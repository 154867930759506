import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext'

//ProtectedRoute 컴포넌트 정의
const ProtectedRoute = () => {
    const { isAuthenticated, isLoading } = useAuth();

    // 로딩 중일 떄 아무것도 렌더링하지 않음
    if (isLoading) {
        return <div>Loading...</div>;
    }

    // 인증되지 않았으면 로그인 페이지로 리다이렉트
    if(!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // 인증되었으면 하위 라우트 렌더링
    return <Outlet />
    
};

export default ProtectedRoute;