import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect( () => {
        // 애플리케이션이 처음 로드될 때 토큰 검증
        const verifyToken = async () => {
            const token = localStorage.getItem('jwtToken');

            if (!token) {
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }

            try {
                // 토큰 검증을 위해 API 호출
                const response = await fetch('/api/verify_token',
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if(!response.ok) {
                    throw new Error('Token verification failed');
                }
                const result = await response.json()

                setIsAuthenticated(true);
            }
            catch(error) {
                console.error(error);
                setIsAuthenticated(false);
                localStorage.removeItem('jwtToken'); // 검증 실패시 토큰 삭제
            }
            finally {
                setIsLoading(false); // 로딩 상태 종료
            }
        };

        verifyToken();
    }, []); // [navigate]); // 의존성 배열에 navigate를 제거하여 불필요한 재렌더링 방지


    const login = (token) => {
        console.log("AuthContext.login called token=", token)
        localStorage.setItem('jwtToken', token);
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem('jwtToken');
        setIsAuthenticated(false);
        //navigate('/'); // 로그아웃 후 로그인 페이지로 리다이렉트
        window.location.href='/';

    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, isLoading }}>
            {!isLoading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext)