import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'

function Home() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth()
  
  async function handleLogin() {
    console.log('Username:', username); // Debugging username
    console.log('Password:', password); // Debugging password

    try {
      const response = await fetch(
        'https://service.partnersai.kr/api/login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            username: username,
            password: password,
            grant_type: 'password',
          }).toString(),
        }
      );

      // Log the entire response data to check what is returned
      const data = await response.json();
      console.log('Server Response:', data);

      if (response.ok) {
        if (data.access_token) {
          //localStorage.setItem('jwtToken', data.access_token);
          console.log('Token saved:', data.access_token); // Check token value
          login(data.access_token)
          navigate('/chat'); // Redirect to chat page on successful login
        } else {
          console.error('No access_token found in response:', data);
          alert('Login failed. No access token received.');
        }
      } else {
        console.error(
          'Login failed with status:',
          response.status,
          'and response:',
          data
        );
        alert('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error during login:', error.message);
      alert('An error occurred. Please try again.');
    }
  }

  //const token = localStorage.getItem('jwtToken');
  //console.log('Retrieved Token:', token); // Check if the token is being retrieved correctly

  return (
    <React.StrictMode>
      <title>PartnersAI</title>
      <div id="logo">
        <img
          src="https://i.imgur.com/31hCDWq.png"
          alt="PartnersAI logo"
          className="logo"
        />
      </div>
      <div className="row justify-content-center">
        <div className="col-3 credentials">
          <input
            className="form-control-home form-control-lg mb-3 custom-rounded"
            type="text"
            placeholder="Email@example.com"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="form-control-home form-control-lg mb-4 custom-rounded"
            type="password" // Changed to password type
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-outline-primary btn-lg mb-3 custom-rounded custom-button"
            onClick={handleLogin}
          >
            로그인
          </button>
          <p>아이디 찾기 | 비밀번호 찾기</p>
        </div>
      </div>

      {/* Footer */}
      <p className="footer">PartnersAI</p>
    </React.StrictMode>
  );
}

export default Home;

