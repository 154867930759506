import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'; // 필요한 경우 추가 CSS 스타일을 정의
import './Settings.css';

function Settings({ onClose }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const currentScrollY = window.scrollY;

    // Settings 모달이 열릴 때 스크롤을 고정시킵니다.
    document.body.style.position = 'fixed';
    document.body.style.top = `-${currentScrollY}px`;

    return () => {
      // Settings 모달이 닫힐 때 스크롤 위치를 복원합니다.
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, currentScrollY);
    };
  }, []);



  const handlePasswordChange = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('새 패스워드가 일치하지 않습니다.');
      return;
    }

    try {
      // API 요청을 통해 패스워드를 변경하는 로직을 추가합니다.
      const response = await fetch('/api/changepw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({ oldpw: oldPassword, newpw: newPassword }),
      });

      if (!response.ok) {
        throw new Error('Failed to change password');
      }

      // 패스워드 변경 성공 후 처리 로직을 추가합니다.
      //alert('Password changed successfully');
      setNewPassword('');
      setConfirmPassword('');
      setError('패스워드가 변경되었습니다.');
    } catch (error) {
      console.error('Password change error:', error);
      //alert('패스워드 변경에 실패했습니다.')
      setError('패스워드 변경에 실패했습니다');
    }
  };

  const isFormValid = oldPassword && newPassword && confirmPassword

  return (
    <div className="settings-overlay"
      //style={{ top: scrollPosition }} // 스크롤 위치에 맞춰서 설정을 위치시킴
    >
      <div className="settings-modal">
        <div className="settings-header">
          <h2>설 정</h2>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </div>
        <div className="settings-body">
          <form onSubmit={handlePasswordChange}>
          <div className="mb-3">
              <label htmlFor="oldPassword" className="form-label">기존 패스워드</label>
              <input
                type="password"
                id="oldPassword"
                className="form-control"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">새 패스워드</label>
              <input
                type="password"
                id="newPassword"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">새 패스워드 확인</label>
              <input
                type="password"
                id="confirmPassword"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <button 
              type="submit" 
              className="btn btn-primary" 
              disabled={!isFormValid || newPassword !== confirmPassword} // Disable button if form is invalid or passwords don't match
            > 패스워드 변경 
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Settings;
