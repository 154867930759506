import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // AuthProvider 가져오기
import ProtectedRoute from './ProtectedRoute';
import Home from './Home';
import Chat from './Chat';

function App() {
  return (
    <AuthProvider> {/* 모든 하위 컴포넌트가 AuthContext를 사용할 수 있게 함*/}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* ProtectedRoute를 사용하여 인증이 필요한 경로 설정 */}
        <Route element={<ProtectedRoute />}>
          <Route path="/chat" element={<Chat />} />
          {/* Add more routes as needed */}
        </Route>
      </Routes>      
    </AuthProvider>
  );
}

// function App() {
//   return (
//     <Routes>
 
      
//        <Route path="/" element={<Home />} />
//      <AuthProvider> {/* 모든 하위 컴포넌트가 AuthContext를 사용할 수 있게 함*/}
//        {/* ProtectedRoute를 사용하여 인증이 필요한 경로 설정 */}
//         <Route element={<ProtectedRoute />}>
//           <Route path="/chat" element={<Chat />} />
//           {/* Add more routes as needed */}
//         </Route>
//    </AuthProvider>
            
//   </Routes>
//     );
// }


export default App;
